html {
  background-color: rgb(42, 40, 40);
  color: gainsboro
}
.navLink {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end;
  align-items: flex-end; */
  /* padding-top: 3%; */
  height: 100vh;
  padding-right: 1%; /* Add desired padding */
}
.orp {
  background: linear-gradient(to right, darkred 50%, navy 50%);
  color: white;
  text-align: center;
  /* padding-right: 150px; */

}
.paj {
  background: navy 50%;
  color: gold;
  text-align: center;
  /* padding-right: 150px; */

}
.r4g {
  background: url('https://round4gym.onrender.com/static/media/logo4W.9f99ef7d208aeb5a49b6.webp');
  background-size: 100% 100%;
  background-color: white;
  color: white;
  text-align: center;
  /* padding-right: 150px; */

}
@keyframes shake {
  0% { transform: translateX(0); }
  1% { transform: translateY(0); }
  25% { transform: translateX(-2px); }
  35% { transform: translateY(-2px); }
  50% { transform: translateX(2px); }
  60% { transform: translateY(2px); }
  75% { transform: translateX(-2px); }
  85% { transform: translateY(-2px); }
  99% { transform: translateX(0); }
  100% { transform: translateY(0); }
}
.shook  {
  animation: shake 0.7s; /* Run the shake animation */
  animation-iteration-count: infinite; /* Loop the animation forever */
}
.shake:hover  {
  animation: shake 0.7s; /* Run the shake animation */
  animation-iteration-count: infinite; /* Loop the animation forever */
}
.bg-ws{
  background-color: whitesmoke;
}
.adjborder {
  border-top: black solid 5px;
  border-right: black solid 10px;
  border-bottom: black solid 10px;
  border-left: black solid 5px;
}
.ttt {
  background-color: rgb(21, 137, 33);
  color: white;
  text-align: center;
}
.header {
  position: fixed;
  top: 0;
  right: 0;

}
.toggle-button {
  width: 30px;
  height: 30px;
  border-radius: 10%;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}
.text-navy{
  color:navy;
}
.font-grey{
  color:rgb(78, 78, 78)
}
.hbl{
  background-color: rgb(231, 145, 24);
  padding: 70% 0%;
  text-align: center;
  border: white 3px solid;
  border-radius: 45%;
  color: black;
  cursor: pointer;
  font-weight: 700;
  text-decoration: wavy;
  margin-bottom: 8%;
}
/* #shorty{
  background-color: darkmagenta;
  padding: 50% 0%;
  text-align: center;
  border: white 3px solid;
  border-radius: 25%;
  color: white;
} */
.Dlogo {
 background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Eo_circle_orange_letter-d.svg/1200px-Eo_circle_orange_letter-d.svg.png");
 background-repeat: no-repeat;
 background-size: cover;
}
.kevo {
	max-width: 100%;
  min-width: 200px;
  max-height: 100%;
  min-height: 100px;
  height:auto;
	background-image: url("./kevo.jpeg");
	background-repeat: no-repeat;
	background-size: cover;
  }
  .kevio {
    max-width: 100%;
    min-width: 200px;
    max-height: 100%;
    min-height: 100px;
    /* height:auto; */
    background-image: url("./kevio.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    }

    .kevio2 {
      /* max-width: 100%;
      min-width: 200px;
      max-height: 100%;
      min-height: 100px;
      height:auto; */
      background-image: url("./kevio.jpeg");
      background-repeat: no-repeat;
      background-size: cover;
      }
.ye {
      /* max-width: 80%;
      min-width: 80%;
      
      height: auto; */
      background-image: url("./ye.jpeg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      }
.margin-0-10{
  margin: 0px 5%;
}
#resume {
        max-width: 100px;
        min-width: 90px;
        height: auto;
        background-image: url("./resume.jpeg");
        background-repeat: no-repeat;
        background-size: contain;
        }
#linkedin {
          max-width: 10px;
          min-width: 90px;
          height: auto;
          background-image: url("./linkedin.png");
          background-repeat: no-repeat;
          background-size: contain;
          }
#github {
            max-width: 10px;
            min-width: 90px;
            height: auto;
            background-image: url("./github.png");
            background-repeat: no-repeat;
            background-size: contain;
            }
.exsect3 {
  text-align: center;
  color:white;
}
.juan2 {
  border: solid white 3px;
  border-radius: 90%;
  /* background-color: rgb(2, 2, 31); */
  padding: 10%;
  align-items: center;
  text-align: center;
  margin:  0px 20% 0px 20%;
  max-width: fit-content;
  min-width: fit-content;
  
}
.exsectP {
  margin: 0 20% 0 5%;
  line-height: 2.5;
  font-weight: bolder;
}    
.exsectSK {
  
  line-height: 2.5;
  font-weight: bolder;
}     
.gooday {
  text-decoration: underline;
  font-weight: bolder;
  color: blue;
  background-color: aqua;
  border-radius: 50%;
  
}  
.font-size-50-26px {
  font-size: 50px;
}
.longday {
  margin-bottom: 15px;
}
.proA {
  display:;
  /* vertical-align: middle;  */
  /* margin-bottom:'150px'; */
  font-size: 16px;
  font-weight:bold;
  
  
}
.text-align-center{
  text-align: center;
}
.home2 {
  background-image: url('./greysbb.gif');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -10%;
  padding-top: 10%;
  padding-bottom: 10%;
  margin-bottom: -10%;
}
.font-size-55px{
  font-size: 55px;
}
.proSect {
  
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThzG0zduqSOrnSBO1xv5FIwpGn9u9c7RjFjEwKN95O5-nyaam6q0Fv3OQZek5vhDc9W1s&usqp=CAU');
    background-repeat: no-repeat;
    background-size: cover;
    
    padding-bottom: 10%;
    margin-bottom: -10%;
  
}

.home {
  background-image: url('https://media.tenor.com/1I1S3wUERPcAAAAd/greys-anatomy.gif');
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20%;
  margin-bottom: -10%;
}
.foot {
  border-top: 4px white solid;
  padding-top: 13px;
  background-color: black;
  padding-bottom: 5%;
  margin-bottom: -7%;
  
}
.footH {
  text-align: center;
   margin-right: ;
   font-family: monospace ;
   font-size: 43px ;
   font-weight: bolder ;

}
.custom-checkbox {
  width: 340px;
  height: 80px;
}
.custom-checkbox input#status {
  display: none;
}
.custom-checkbox input#status + label {
  height: 100%;
  width: 100%;
}
.custom-checkbox input#status + label > .status-switch {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: grey;
  color: white;
  transition: all 0.5s ease;
  padding: 3px;
  border-radius: 3px;
}
.custom-checkbox input#status + label > .status-switch:before, .custom-checkbox input#status + label > .status-switch:after {
  border-radius: 2px;
  height: calc(100% - 6px);
  width: calc(50% - 3px);
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  transition: all 0.3s ease;
}
.custom-checkbox input#status + label > .status-switch:before {
  background-color: white;
  color: black;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
  left: 3px;
  z-index: 10;
  content: attr(data-unchecked);
}
.custom-checkbox input#status + label > .status-switch:after {
  right: 0;
  content: attr(data-checked);
}
.custom-checkbox input#status:checked + label > .status-switch {
  background-color: #40c253;
}
.custom-checkbox input#status:checked + label > .status-switch:after {
  left: 0;
  content: attr(data-unchecked);
}
.custom-checkbox input#status:checked + label > .status-switch:before {
  color: #40c253;
  left: 50%;
  content: attr(data-checked);
}

.icon1 {
  min-height: 90px;
  width: 90px;
  background-color: black;
  /* margin-left: 32%; */
  border: white 2px solid;
  border-radius: 58%;
  cursor: pointer;
}

.icon2 {
  height: 90px;
  width: 90px;
  background-color: white;
  margin-left: 25px;
  border: white 2px solid;
  border-radius: 8%;
  cursor: pointer;
}

.icon3 {
  height: 90px;
  width: 90px;
  margin-left: 25px;
  border: white 2px solid;
  border-radius: 58%;
  cursor: pointer;
  
}
.navbutton {
  color: rgb(2, 2, 31);
  background-color: orange;
  height: 10%;
  border-radius: 25%;
  border: 10px rgb(2, 2, 31) solid ;
  padding:'5%';
  font-size: bolder ;
}
.cert  {
  background-image: url("./cert1.png ");
	background-repeat: no-repeat;
	background-size: cover;
  /* background-color: blue; */

  
}
.cert2  {
  background-image: url("./cert2.jpeg ");
	background-repeat: no-repeat;
	background-size: cover;
  /* background-color: blue; */

}
.respic  {
  background-image: url("./respre.png ");
	background-repeat: no-repeat;
	background-size: cover;
  /* background-color: blue; */

}

.ca1  {
  background-image: url("./kdjs.png ");
	background-repeat: no-repeat;
	background-size: cover;
  /* background-color: blue; */

  
}
.ca2  {
  background-image: url("./kdhtml.png ");
	background-repeat: no-repeat;
	background-size: cover;
  /* background-color: blue; */

  
}
.ca3  {
  background-image: url("./kdcss.png ");
	background-repeat: no-repeat;
	background-size: cover;
  /* background-color: blue; */

  
}
.bigGuy {
  font-weight: bolder;
  font-size: 90px;
  text-align: center;
  background-color: rgb(2, 2, 31);
  padding-top: 10%;
  margin-top: -7%;
  padding-bottom: 2% 

}
.akavid {
  border-radius: 25%;
  /* width: 710px;
  height: 400px;
  border: navy 5px solid; */
  /* margin: 5%; */
  /* margin-left: 20%; */
}
.amtri {
  border-radius: 25%;
  width: 70%;
  height: 400px;
  border: navy 5px solid;
  margin-top: 5%;
}
.proT {
  font-size: 45px;
  font-weight: bolder;
  }
.cft {
  background-color: black;
  color:darkred;
  text-align: center;
  
  
}
.jcc{
  justify-content: center;
  align-items: center;
}
.textC {
  text-align: center;
}
.krt {
  background-color: yellow;
  color:black;
  text-align: center;
  
}
.rbg {
  background-color: lightblue;
  color:black;
  text-align: center;
}
.homeBorder {
  border-top: black 15px  solid;
  border-bottom: black 15px  solid;
  border-radius: 10%;
}
.color-black{
  color: black;
}
.newProHome {
  font-weight: bold;
  border:white 3px solid;
  text-shadow: white;
  color: purple;
  background-color: white;
  padding : 1px;
  text-decoration: underline;
  cursor: pointer;

}
.codinggif {
  margin-left: 5%;
  width: 80%;
  height: 250px;
  background-color: navy;
  border-radius: 25%;
}
.width100{
  max-width: 100% ;
  min-width: 100%;
}
.zI1 {
  z-index: 0;
}
.landstrom{
  text-decoration: underline;
}

.zI2 {
  z-index: -2;
}

.zI3 {
  z-index: -3;
}
.fs35{
  font-size: 35px;
}
.zI4 {
  z-index: -4;
}

.zI5 {
  z-index: 5;
}
.font-size-50-36px{
  font-size: 50px;
}
#display-none {
  display: none;
  max-width: 1px;
  max-height: 1px;
}
@media (max-width: 640px) {
.margin70pxleft-at-640{
  margin-left: 70px;
}
}
@media (max-width: 767px) {

  .margin70pxleft-at-760{
    margin-left: 70px;
  }
  .font-size-60px {
    font-size: 60px;
  }
  .juan2 {
    border: solid navy 3px;
    border-radius: 90%;
    /* background-color: rgb(2, 2, 31); */
    padding: 10%;
    align-items: center;
    text-align: center;
    margin:  0px 2% 0px 2%;
    max-width: fit-content;
    min-width: fit-content;
    
  }


  .home2 {
  background-image: url('./greysbb.gif');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -10%;
  padding-top: 10%;
  padding-bottom: 15%;
  margin-bottom: -10%;
}

  .proT {
  font-size: 25px;
  }
  .jose {
    margin-right: 25%;

  }
  .font-size-55px {
    font-size: 40px;
    padding-bottom: 60px;
  }
  .font-size-25px {
    font-size: 25px;
  }
  .font-size-20px {
    font-size: 20px;
  }
  .amtri {
    border-radius: 25%;
    width: 80%;
    height: 250px;
    border: rgb(2, 2, 31) 5px solid;
    margin-top: 5%;
    
    
  }
  #midcft {
    /* padding-right: 20%; */

  }
  .font-size-50-36px{
    font-size: 36px;
  }
  .font-size-50-26px{
    font-size: 26px;
  }
  .exsect3 {
    /* padding-top: 20px;
    margin-top: -3%; */
    /* padding-bottom: 1%; */
    /* margin-right: 10%; */
    /* padding-right: 10%; */
  }
  .akavid {
    /* width: 75%;
    height: 400px;
    margin-left: 1%; */

  }
  .foot {
    padding-bottom: 10%;
  }
  .footH {
    font-size: 25px;
  }
  .icon1 {
    /* margin-left: 15%; */
    min-height: 65px;
    
    
  }
  #resume {
    max-width: 65px;
    min-width: 65px;
  }
  #linkedin {
    max-width: 65px;
    min-width: 65px;
  }
  #github {
    max-width: 65px;
    min-width: 65px;
  }
  .wttc {
    font-size: 20px;
  }

  .exsectP {
    margin: 0 0% 0 2%;
    line-height: 2.5;
    margin-right: 27%;
  }
        


  .kevo,
  .kevio {
    /* height: 170px; */
    min-width: 170px;
    background-size: cover;
  }

  .ye {
    /* background-size:contain; */

  }
  .upUp {
    margin-top: -220px;
  }

  iframe {
    width: 100%;
    height: 200px;
    margin-top: 5%;
  }


  .custom-checkbox {
    width: 50%;
    height: 60px;
    

  }
  .hbl{
    background-color: rgb(231, 145, 24);
    padding: 45% 0%;
    text-align: center;
    border: white 3px solid;
    border-radius: 45%;
    color: black;
    cursor: pointer;
    font-weight: 700;
    text-decoration: wavy;
    margin-bottom: 8%;
  }

}
        
      